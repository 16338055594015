<template>
  <v-container>
    <v-row justify="space-between">
      <v-col class="mt-4" cols="6"> 
        <v-progress-circular
        :width="3"
        color="green"
        :indeterminate="load"
        v-if="showLoad"
        class="mt-5"
      ></v-progress-circular>
      </v-col>
      <v-col cols="6" align="right">
        <v-btn-toggle v-model="timeStatus" color="primary">
          <v-btn text small style="height: 50px" value="week">Weekly</v-btn>
          <v-btn text small style="height: 50px" value="month"> Monthly </v-btn>

          <v-btn text small style="height: 50px" value="quarterly">
            Quarterly
          </v-btn>
          <v-btn text small style="height: 50px" value="year"> 1 Year </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="stats">
        <v-row>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n2
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{ stats.customersTotal }} <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Total Customers </span><v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Customers </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n2
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{ stats.customersBanned }} <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Block Customers </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Block Customers </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n2
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{ stats.customersActiveTotal }} <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Active Customers </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of Active Customers </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n2
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{ stats.customersInActiveTotal }} <br />
                    <v-list-item-subtitle>
                      <span class="black--text">InActive Customer </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Number of InActive Customer </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{ stats.inActive_customers_growth_rate }}%
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text"
                        >Inactive Custo.. Growth Rate
                      </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Growth Rate of the Inactive Customers </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{ stats.customers_growth_rate }}%<br />
                    <v-list-item-subtitle>
                      <span class="black--text">Growth Rate </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span> Total Growth Rate </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>

          <v-col cols="3">
            <v-card
              class="
                rounded-tl rounded-tr rounded-bl rounded-br
                mt-n4
                elevation-1
              "
              color="white"
              flat
            >
              <v-list-item>
                <v-list-item-content class="pa-2">
                  <v-list-item-title class="text-h5 mb-1">
                    {{ stats.avgRating.length? stats.avgRating[0].AverageRating : 0 }}
                    <br />
                    <v-list-item-subtitle>
                      <span class="black--text">Avg Satisfaction Score </span>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span>
                            <v-icon
                            small
                              v-bind="attrs"
                              v-on="on">
                              mdi-help-circle
                              </v-icon>
                          </span>
                          </template>
                          <span>Average Customer Satisfaction Score </span>
                          </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col cols="5">
        <v-btn-toggle v-model="usersStatus" color="primary">
          <v-btn text small style="height: 50px" value="all"> All </v-btn>

          <v-btn text small style="height: 50px" value="active"> Active </v-btn>
          <v-btn text small style="height: 50px" value="pending">
            Pending
          </v-btn>

          <v-btn text small style="height: 50px" value="blocked">
            Blocked
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col class="mt-3" cols="6">
        <v-row>
          <div style="width: 200px">
            <date-filter :init-date="initDate" @dateChange="onDateChange" />
          </div>
          <v-text-field
            label="Search Customers"
            solo
            append-icon="mdi-magnify"
            @change="search"
            style="width: 200px"
          />
          <v-btn
            color="primary"
            @click="userFormOpen = true"
            style="width: 95px; height: 47px; margin-left: 5px"
          >
            <v-icon small> mdi-plus </v-icon>
            Add New
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="users.data"
        :options.sync="tableOptions"
        :server-items-length="tableOptions.totalItems"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': tableOptions.rowsPerPageItems,
        }"
      >
        <template v-slot:[`item.first_name`]="{ item }">
          {{ item.firstName }} {{ item.surName }}
        </template>
        <template v-slot:[`item.isVerified`]="{ item }">
          <v-chip
            class="ma-2"
            label
            outlined
            small
            :color="getColor(item)"
          >
            {{ item.banned ? "BLOCKED" : item.isVerified ? "ACTIVE" : "PENDING"  }}
          </v-chip>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ getReadableDate(item.createdAt) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip :key="index" bottom v-if="item.isVerified === false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="pendingToggle(item)"
              >
                mdi-account-check
              </v-icon>
            </template>
            Active this user
          </v-tooltip>
          <v-tooltip :key="index" bottom v-if="item.banned === true">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="pendingToggle(item)"
              >
                mdi-account-check
              </v-icon>
            </template>
            Active this user
          </v-tooltip>
          <v-tooltip :key="index" bottom v-if="item.isVerified === true && item.banned ===false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="blockToggle(item)"
              >
                mdi-account-cancel
              </v-icon>
            </template>
            Block this user
          </v-tooltip>
          <v-tooltip :key="index" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="goToProfile(item._id)" 
              >
                mdi-eye
              </v-icon>
            </template>
            View
          </v-tooltip>
          <v-tooltip :key="index" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="deleteCustomer(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            Delete
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <user-form
      :open="userFormOpen"
      user-type="customer"
      @close="userModalClose"
    />
  </v-container>
</template>
<script>
import userForm from "@/components/userForm";
import DateFilter from "@/components/dateFilter";
import {
  getUsers,
  approveUser,
  blockUser,
deleteUser,  getUsersStats,
} from "@/services/users";
export default {
  components: { userForm, DateFilter },
  data() {
    return {
      userFormOpen: false,
      usersStatus: "all",
      timeStatus: "week",
      load: false,
      showLoad: false,
      stats: null,
      headers: [
        
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "first_name",
        },
        {
          text: "Phone No",
          align: "start",
          sortable: false,
          value: "phone",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "isVerified",
        },
        {
          text: "Registration Date",
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "Action",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      users: {
        data: [],
        actions: ["block", "view", "pending"], //users action
      },
      tableOptions: {
        page: 1,
        itemsPerPage: 200,
        totalItems: 0,
        rowsPerPageItems: [200, 500, 1000, 2000],
      },
      loading: true,
      searchKeyword: "",
      dateFilter: null,
      initDate: [],
    };
  },
  watch: {
    tableOptions: {
      handler() {
        this.loading = true;
        const _self = this;
        setTimeout(function () {
          _self.getUsersData();
        }, 500);
      },
      deep: true,
    },
    usersStatus() {
      this.loading = true;
      this.getUsersData();
    },
    timeStatus() {
      this.getUsersTimeStats();
    },
  },
  async mounted() {
    window.onload = function () {
      localStorage.removeItem('filterDate');
    }
    const date = new Date();
    // if (!localStorage.getItem("filterDate")) {
    //   this.initDate.push(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
    //   this.initDate.push(
    //     `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    //   );
    // } else {
    //   let datearr = localStorage.getItem("filterDate").split(",");
    //   let date1 = new Date(datearr[0]);
    //   let date2 = new Date(datearr[1]);
    //   this.initDate.push(
    //     `${date1.getFullYear()}-${date1.getMonth() + 1}-${date1.getDate()}`
    //   );
    //   this.initDate.push(
    //     `${date2.getFullYear()}-${date2.getMonth() + 1}-${date2.getDate()}`
    //   );
    // }
    this.getUsersData();
    this.getUsersTimeStats();
  },
  methods: {
    search(keyword) {
      this.searchKeyword = keyword;
      this.getUsersData();
    },
    userModalClose() {
      this.userFormOpen = false;
      this.getUsersData();
    },
    onDateChange(newValue) {
      if (newValue.length < 2) return;
      localStorage.setItem("filterDate", newValue);
      let finaldate = localStorage.getItem("filterDate").split(",");
      this.dateFilter = localStorage.getItem("filterDate")
        ? [new Date(finaldate[0]), new Date(finaldate[1])]
        : newValue;
      this.getUsersData();
    },
    async getUsersData() {
      this.loading = true;
      const params = {
        status: this.usersStatus === "all" ? null : this.usersStatus,
        search: this.searchKeyword,
        limit: this.searchKeyword ? 30 : this.tableOptions.itemsPerPage,
        page: this.tableOptions.page,
        type: "customer",
        date: this.searchKeyword ? null : this.dateFilter?.join(","),
      };
      getUsers(params).then((res) => {
        this.users.data = res.data.Users;
        this.tableOptions.totalItems = res.data.count;
        this.loading = false;
      });
      const userStatsParams = {
        status: this.timeStatus === "all" ? null : this.timeStatus,
      };
      getUsersStats(userStatsParams).then((res) => {
        this.stats = res.data;
        console.log(this.stats,"asdf");
      });
    },
    async getUsersTimeStats() {
      this.load = true;
      this.showLoad = true;
      const userStatsParams = {
        status: this.timeStatus === "all" ? null : this.timeStatus,
      };
      getUsersStats(userStatsParams).then((res) => {
        this.stats = res.data;
        this.load = false;
        this.showLoad = false;
        console.log(this.stats);
      });
    },
    pendingToggle(item) {
      const status = item.status === "pending" ? "active" : "pending"; //extra
      approveUser(item._id, { status: status }).then((res) => {
        this.getUsersData();
      });
    },
    blockToggle(item) {
      const status = item.status === "blocked" ? "active" : "blocked"; //extra
      blockUser(item._id, { status: status }).then((res) => {
        this.getUsersData();
      });
    },
    goToProfile(id) {
      this.$router.push({ name: "Customer Profile", params: { id: id } });
    },
    getReadableDate(date) {
      const d = new Date(date);
      return d.toUTCString();
    },
    getColor(item) {
      return item.banned ? "red" : item.isVerified ? "green" : "orange" 
      
    },
    deleteCustomer(item) {
      if (confirm("Are your Sure?")) {
        deleteUser(item._id).then((res) => {
          if (res.data){
             this.getUsersData();
             return alert("User Delete Successfully!");}
          else return alert("Try Again! Some went wrong.");
        });
      }
    },
  },
};
</script>
